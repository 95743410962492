import Vue from "vue";
import Landing from "@/views/Landing";
import vuetify from "./plugins/vuetify";
import "./plugins/vuetify-mask";
import store from "./store";
import "@/sass/variables.sass";

Vue.config.productionTip = false;

new Vue({
    vuetify,
    store,
    render: h => h(Landing),
}).$mount("#app");
