<template>
  <v-app style="height: 0; z-index: 99999;">
    <checkout-dialog v-if="checkoutDialog" v-model="checkoutDialog" :facility="facility" :unit="unit"/>
  </v-app>
</template>
<script>
import Vue from "vue";
import CheckoutDialog from "@/components/CheckoutDialog";

export default Vue.extend({
  name: "Landing",
  components: {
    CheckoutDialog,
  },
  data() {
    return {
      checkoutDialog: false,
      facility: "",
      unit: "",
    };
  },
  mounted() {
    document.querySelectorAll("[data-target=\"#modal__rent\"]")
        .forEach(item => item.addEventListener("click", (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.facility = item.dataset.facility;
          this.unit = item.dataset.unit;
          this.checkoutDialog = true;
        }));
  },
});
</script>
